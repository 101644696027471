// Theme Colors/Branding
$brand-primary: #e22726;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #22284b;
$dark: #3E4147;
$light: #f5f5f5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: darken($brand-secondary,10%);
$accent-2: #e22726;
$body-font: #222;
$error: #d81e00;