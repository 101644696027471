@media (max-width: 2199px) {
	h1.h4,
	h1.h4 small,
	h2.h3,
	h3.h5,
	h5.secondary {
		display: none;
	}
}

h1.h4 {
	font-size: 1.1rem;
	background: #f2f2f2;
	padding: 10px;
	text-align: center;
	margin-bottom:0;
	margin-top:10px;
}

h2.h3 {
	font-size: 1.1rem;
	background: #f2f2f2;
	padding: 10px;
	text-align: center;
	font-weight: 500;
	margin-bottom:0;
}

h3.h5 {
	font-size: 1.1rem;
	background: #c0c0c0;
	color: #000;
	padding: 10px;
	text-align: center;
	margin-bottom:0;
}

.main h1.h4 small:after {
	content: " and surrounding communities";
}

.hidden {
	display: none !important;
}

.bodyimgright,
.container img.bodyimgright,
.plugin-img,
.sidebar img.bodyimgright {
	float: right;
	max-width: 30%;
}

.seo {
	display: none;
}
@media (min-width:2200px) {
	.seo {
		display: block;
	}
}
