body {
  background: white;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

// Accessibility Plugin Adjustments

#pojo-a11y-toolbar {
  height: 100%;
  top: 0!important;
}

.pojo-a11y-toolbar-toggle {
  bottom: 0!important;
}

.pojo-a11y-toolbar-overlay {
  position: absolute!important;
  bottom: 0!important;
  right: 0!important;
}

/** custom classes */

.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 50vw;
  @include media-breakpoint-up(md) {
    min-height: 33vw;
  }
  @include media-breakpoint-up(lg) {
    min-height: 350px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.square {
  padding-top: 100%;
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.light-box-shadow {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
}

.min-height-smaller {
  min-height: 33vh;
  @include media-breakpoint-up(md) {
    min-height: 300px;
  }
}

.min-height-md-down {
  @include media-breakpoint-down(md) {
    min-height: 400px;
  }
}

.pad-top-100 {
  padding-top: 100%!important;
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;
}

.large-icon * {
  font-size: 2.5em;
  @include media-breakpoint-up(md) {
    font-size: 3em;
  }
  @include media-breakpoint-up(md) {
    font-size: 4em;
  }
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;

  &.small-bio {
    padding: 30px;
    border: solid 5px #fff;
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

.negative-top-header {
  margin-top: -47px;
  @include media-breakpoint-up(md) {
    margin-top: -115px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -130px;
  }
}

.clip-top {
  clip-path: polygon(0px 25px, 100% 0, 100% 100%, 0px 100%);
}

.clip-oblique {
  clip-path: polygon(0px 0px, 100% 3%, 100% 97%, 0px 100%);
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0px 0px, 100% 8%, 100% 92%, 0px 100%);
  }
}

.border-bottom-trans-white {
  border-bottom: solid 1px rgba(255,255,255,.1);
}

.box-shadow {
  box-shadow: 0 0 30px rgba(0,0,0,0.1);
}

.box-shadow-small {
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
// Images

.img-wrap {
  img {
    max-height: 75px;
    margin: 10px;
  }
}

img {
  max-width: 100%;
}

.img-on-dark-bg {
  filter: brightness(0) invert(1);
}
// Util

.absolute-link {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.absolute-positioner {
  top: 0;
  left: 0;
}

.opacity-soft {
  opacity: 0.97;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-85 {
  opacity: 0.85;
}

.half-opacity {
  opacity: 0.5;
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}

.z-index-100 {
  z-index:100;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 200px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}

// custom
.background-medium{
	background: $medium;
}
.small_box_shadow{
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

// header
.sticky-top{
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

// modal
@media (min-width: 576px){
	.modal-dialog {
		max-width: 75%;
		margin: 1.75rem auto;
	}
}
.single_location_card{
	min-height: 340px;
}
.location_image_link {
    min-height: 266px;
}
.background_none {
    background: none !important;
	background-color: transparent !important;
}

.hero_h1, .inner_hero_h1{
	color: #FFF;
    margin-bottom: 0;
    text-shadow: 8px 8px 12px #22284b;
}
.super_shadow{
	text-shadow: 8px 8px 12px #000;
}
.no_margin_p{
	p{
		margin: 0;
		padding: 0;
	}
}

.location_image_link_static{
	min-height: 260px;
}

// cat blade
.cat_inner_wrap{
	min-height: 250px;
	background: $light;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	background-repeat: no-repeat !important;
    background-size: cover !important;
	border-radius: $border-radius;
	transition: all 0.2s ease-in;
	  
	  &:hover{
		  transform: translate(0px, 4px);
	  }
	  .h2{
		  transition: all 0.2s ease-in;
		  &:hover{
			  color: $brand-secondary !important;
		  }
	  }
}
/*
.cat_inner_wrap_1{
	background: linear-gradient(0deg, rgba(34, 40, 75, 0.7), rgba(34, 40, 75, 0.7)), url(../../images/cb1.jpg);
}
.cat_inner_wrap_2{
	background: linear-gradient(0deg, rgba(34, 40, 75, 0.7), rgba(34, 40, 75, 0.7)), url(../../images/cb2.jpg);
}
.cat_inner_wrap_3{
	background: linear-gradient(0deg, rgba(34, 40, 75, 0.7), rgba(34, 40, 75, 0.7)), url(../../images/cb3.jpg);
}
.cat_inner_wrap_4{
	background: linear-gradient(0deg, rgba(34, 40, 75, 0.7), rgba(34, 40, 75, 0.7)), url(../../images/cb4.jpg);
}
.cat_inner_wrap_5{
	background: linear-gradient(0deg, rgba(34, 40, 75, 0.7), rgba(34, 40, 75, 0.7)), url(../../images/cb5.jpg);
}
.cat_inner_wrap_6{
	background: linear-gradient(0deg, rgba(34, 40, 75, 0.7), rgba(34, 40, 75, 0.7)), url(../../images/cb6.jpg);
}
.cat_inner_wrap_7{
	background: linear-gradient(0deg, rgba(34, 40, 75, 0.7), rgba(34, 40, 75, 0.7)), url(../../images/cb7.jpg);
}
.cat_inner_wrap_8{
	background: linear-gradient(0deg, rgba(34, 40, 75, 0.7), rgba(34, 40, 75, 0.7)), url(../../images/cb8.jpg);
}
*/
.cat_inner_wrap{
	background: $brand-primary;
	border: 5px solid #FFF;
    outline: 5px solid $brand-primary;
}

// banner logos
.logo-banner-single {
	background: #FFF;
    padding: 16px;
    border-radius: 6px;
    min-width: 332px;
    min-height: 332px;
    display: flex;
}

// location heros
.location_logo_hero{
	  @include media-breakpoint-up(lg) {
		 max-width: 650px !important;
	  }
}


// footer
.footer ul{
	padding-left: 0;
	list-style-type: none;
}
.notunder1500{
	text-align: center;
	
	.btn-primary{
		background: #000;
		width: 100%;
	}
}